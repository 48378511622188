import { format, distanceInWords, differenceInDays } from "date-fns";
import nl from "date-fns/locale/nl";
import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import AuthorList from "./author-list";
import stylesMain from "./main.module.css";
import stylesBlog from "./blog-post.module.css";
import PortableText from "./portableText";
import Container from "./container";
import BlogPostPreview from "./blog-post-preview";

function BlogPost(props) {
  const { _rawBody, authors, relatedPosts, title, mainImage, publishedAt, fullUrl } = props;
  return (
    <article itemScope itemType="https://schema.org/Blog">
      <meta itemProp="url" content={fullUrl}></meta>
      {mainImage && mainImage.asset && (
        <div className={stylesMain.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1920)
              .height(Math.floor((9 / 16) * 1920))
              .fit("crop")
              .auto("format")
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={stylesMain.mainContent}>
          <h1 itemProp="name" className={stylesMain.title}>
            {title}
          </h1>
          <div itemProp="datePublished" className={stylesBlog.publishedAt} dateTime={publishedAt}>
            {differenceInDays(new Date(publishedAt), new Date()) > 3
              ? distanceInWords(new Date(publishedAt), new Date(), { locale: nl })
              : format(new Date(publishedAt), "D MMMM YYYY", { locale: nl })}
          </div>
          <div>
            {/* {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )} */}
          </div>
          {_rawBody && <PortableText blocks={_rawBody} />}

          {authors && <AuthorList items={authors} title="Geschreven door" />}

          {relatedPosts && relatedPosts.length > 0 && (
            <>
              <h2 className={stylesBlog.relatedArticlesHeadline}>
                {relatedPosts.length === 1 ? "Gerelateerd artikel" : "Gerelateerde artikelen"}
              </h2>
              <div className={stylesBlog.relatedPosts}>
                {relatedPosts.map((relatedPost) => (
                  <BlogPostPreview titleTag="h4" {...relatedPost} fullUrl={fullUrl} />
                ))}
              </div>
            </>
          )}
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;
