import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import BlogPost from "../components/blog-post";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { getBlogUrl } from "../lib/helpers";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    siteMeta: site {
      siteMetadata {
        siteUrl
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      metaTitle
      metaDescription
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      relatedPosts {
        id
        publishedAt
        title
        mainImage {
          ...SanityImage
          alt
        }
        slug {
          current
        }
      }
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const fullUrl =
    data &&
    data.siteMeta &&
    data.siteMeta.siteMetadata &&
    `${data.siteMeta.siteMetadata.siteUrl}${getBlogUrl(post.slug.current)}`;

  return (
    <Layout>
      {errors && <SEO metaTitle="GraphQL Error" />}
      {post && (
        <SEO
          lang={"nl"}
          fullUrl={fullUrl}
          metaTitle={post.metaTitle}
          metaDescription={post.metaDescription}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <BlogPost {...post} fullUrl={fullUrl} />}
    </Layout>
  );
};

export default BlogPostTemplate;
