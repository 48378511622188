import { format } from "date-fns";
import nl from "date-fns/locale/nl";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

import styles from "./blog-post-preview.module.css";
import typographyStyles from "./typography.module.css";

function BlogPostPreview(props) {
  const TitleTag = props.titleTag ? props.titleTag : "h3";

  return (
    <article itemScope itemType="https://schema.org/Blog" key={props.id}>
      <meta itemProp="url" content={props.fullUrl}></meta>
      <Link
        title={`Lees blogartikel "${props.title}"`}
        className={props.isInList ? styles.inList : styles.inGrid}
        to={getBlogUrl(props.slug.current)}
      >
        <div className={styles.leadMediaThumb}>
          {props.mainImage && props.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(600)
                .height(Math.floor((9 / 16) * 600))
                .auto("format")
                .url()}
              alt={props.mainImage.alt}
            />
          )}
        </div>
        <div className={styles.text}>
          <TitleTag itemProp="name" className={cn(typographyStyles.responsiveTitle3, styles.title)}>
            {props.title}
          </TitleTag>
          {props._rawExcerpt && (
            <div className={styles.excerpt}>
              <PortableText blocks={props._rawExcerpt} />
            </div>
          )}
          <div itemProp="datePublished" dateTime={props.publishedAt} className={styles.date}>
            {format(props.publishedAt, "D MMMM YYYY", { locale: nl })}
          </div>
        </div>
      </Link>
    </article>
  );
}

export default BlogPostPreview;
